import { FormControl, TextField } from "@mui/material";
import { CreateDesignOfficeDto } from "../dtos/create-design-office.dto";

interface DesignOfficeComponentProps {
    designOffice: CreateDesignOfficeDto;
    handleChangeDesignOffice: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DesignOfficeComponent: React.FC<DesignOfficeComponentProps> = ({designOffice, handleChangeDesignOffice}) => {
    return (
        <FormControl className="flex flex-col justify-center items-center w-full space-y-2">
            <TextField
                id="businessName"
                name="businessName"
                label="Nom du bureau d'étude"
                type="text"
                variant="outlined"
                size="small"
                value={designOffice.businessName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDesignOffice(event)}
            />

            <TextField 
                id="email"
                name="email"
                label="Email"
                type="email"
                variant="outlined"
                size='small'
                value={designOffice.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDesignOffice(event)}
            />

            <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone number"
                type="tel"
                variant="outlined"
                size='small'
                value={designOffice.phoneNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDesignOffice(event)}
            />
        </FormControl>
    )
}

export default DesignOfficeComponent;