import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./context/authContext";
import Routes from "./routes/routes";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
