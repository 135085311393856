import { FormControl, TextField } from "@mui/material";
import { CreateAddressDto } from "../dtos/create-address.dto";

interface AddressComponentProps {
    address: CreateAddressDto;
    handleChangeAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddressComponent: React.FC<AddressComponentProps> = ({address, handleChangeAddress}) => {
    return (
        <FormControl className="flex flex-col justify-center items-center w-full space-y-2">
            <TextField 
                id="number"
                name="number"
                label="Numéro"
                type="text"
                variant="outlined"
                size='small'
                value={address.number}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAddress(event)}
            />
            <TextField 
                id="street"
                name="street"
                label="Nom de rue"
                type="text"
                variant="outlined"
                size='small'
                value={address.street}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAddress(event)}
            />
            <TextField 
                id="zipCode"
                name="zipCode"
                label="Code postal"
                type="number"
                variant="outlined"
                size='small'
                value={address.zipCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAddress(event)}
            />
            <TextField 
                id="city"
                name='city'
                label="Ville"
                type="text"
                variant="outlined"
                size='small'
                value={address.city}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAddress(event)}
            />
            <TextField 
                id="country"
                name="country"
                label="Pays"
                type="text"
                variant="outlined"
                size='small'
                value={address.country}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeAddress(event)}
            />
        </FormControl>
    )
}

export default AddressComponent;