import { useEffect } from "react";
import { Person } from "../models/person.model";
import useLocalStorage from "./useLocalStorage";
import usePerson from "./usePerson";

const useAuth = () => {
    const { person, addPerson, removePerson } = usePerson();
    const { getItem } = useLocalStorage();

    useEffect(() => {
        const personLocalStorage = getItem("person")
        if (personLocalStorage) {
            addPerson(JSON.parse(personLocalStorage));
        }
    }, []);

    const login = ((person: Person) => {
        addPerson(person);
    })

    const logout = () => {
        removePerson();
    }

    return { person, login, logout };
}

export default useAuth;