import { Box, Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import logo42b from "../assets/42b_logo.png";
import axios from "../axios/axios";
import useAuth from "../hooks/useAuth";

const Login = () => {
  const { person, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const passwordRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  if (person) navigate("/home")

  const loginEvent = () => {
    const credentials = {
      email: email,
      password: password,
    };

    axios
      .post("/auth/login", credentials)
      .then((response) => {
        const accessToken = response.data.accessToken;
        const headers = {'Authorization': 'Bearer ' + accessToken}
        axios.get("/person/me", { headers })
        .then((response) => {
          login({
            id: response.data.personId,
            email: response.data.email,
            role: response.data.role,
            accessToken: accessToken
          });
          navigate("/home");
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDownEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      passwordRef.current?.focus();
    }
  }

  const handleKeyDownPasssword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      loginEvent();
    }
  }

  return (
    <Box>
      <Box className="flex flex-col w-full">
        <Box className="flex justify-center mt-6">
          <img src={logo42b} className="w-12%" alt="Logo 42B" />
        </Box>
        <Box className="flex justify-center items-center h-full">
          <Box className="flex flex-col justify-center items-center bg-white rounded-3xl w-1/3 py-10 border border-[#bcbcbc] shadow-xl">
            <h4 className="text-black text-2xl font-bold text-lg mb-5">
              Connexion
            </h4>
            <TextField
              className="!mb-4 w-2/3"
              type="email"
              id="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDownEmail}
            />
            <TextField
              className="!mb-6 w-2/3"
              type="password"
              id="password"
              inputRef={passwordRef}
              label="Mot de passe"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDownPasssword}
            />
            <Button
              className="!bg-black"
              variant="contained"
              size="large"
              onClick={loginEvent}
            >
              Confirmer
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
