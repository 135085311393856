import { ReactNode, createContext, useState } from "react";
import { AuthContextDto } from "../dtos/authContext.dto";
import { Person } from "../models/person.model";

type Props = {
  children?: ReactNode;
}

const AuthContext = createContext<AuthContextDto>({
  person: null,
  setPerson: () => {}
});

const getInitialState = () => {
  const person = localStorage.getItem("person")
  return person ? JSON.parse(person) : null;
}

const AuthProvider = ({children}: Props) => {
  const [person, setPerson] = useState<Person | null>(getInitialState);

  return (
    <AuthContext.Provider value={{person, setPerson}}>
      {children}
    </AuthContext.Provider>
  )
};

export { AuthContext, AuthProvider };

