import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Person } from "../models/person.model";
import useLocalStorage from "./useLocalStorage";

const usePerson = () => {
    const { person, setPerson } = useContext(AuthContext);
    const { setItem, removeItem } = useLocalStorage();

    const addPerson = (personParam: Person) => {
        setPerson(personParam);
        setItem("person", JSON.stringify(personParam));
    }

    const removePerson = () => {
        setPerson(null);
        removeItem("person");
    }

    return { person, addPerson, removePerson }
}

export default usePerson;