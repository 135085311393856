import { Box, Link } from "@mui/material";
import logo42b from "../assets/42b_logo.png";
import Nav from "./Nav";
import useAuth from "../hooks/useAuth";

const Header = () => {
  const { person } = useAuth();

  return (
    <Box>
      {person ? (
        <Box className="flex justify-between bg-white py-1 shadow-lg px-7 py-3">
          <Box className="w-3/4">
            <Link className="flex w-10%" href={process.env.REACT_APP_URL_FRONT}>
              <img src={logo42b} alt="Logo 42B" />
            </Link>
          </Box>
          <Nav />
        </Box>
      ): <Box></Box>}
    </Box>

  );
};

export default Header;
