import { Box } from "@mui/material";
import { Navigate, Outlet, Route, Routes as Router, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Form from "../pages/Form";
import axios from "../axios/axios";
import BuildingPage from "../pages/BuildingPage";

const PrivateRoutes = () => {
    const { person } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    
    const urlSearchParams = new URLSearchParams(location.search)

    if (urlSearchParams.has('accessToken')) {
      const accessToken = urlSearchParams.get('accessToken');
      axios.post<boolean>('/auth/token-validate', {accessToken: accessToken})
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
              console.log(error)
              navigate('/login');
          })
    } else if (!person) {
      return <Navigate to='/login' replace /> 
    }

    return <Outlet/>;
}

const Routes = () => (
  <Box className="flex flex-col h-screen bg-[#f0f0f0]">
    <Header />
    <Box className="flex h-full">
      <Router>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route path="/building/:buildingId" element={<BuildingPage />} />
          <Route path="*" element={<Navigate to='/home' />}/>
          <Route path="/building/:buildingId/form/:recordId" element={<Form />} />
        </Route>
      </Router>
    </Box>
    {/* TODO FOOTER */}
    {/* <Footer /> */}
  </Box>
)

export default Routes;