import ApartmentIcon from '@mui/icons-material/Apartment';
import { Box, Button, CardActionArea, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from "@mui/material";
import { Address } from "cluster";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import axios from "../axios/axios";
import { CreateAddressDto } from "../dtos/create-address.dto";
import { CreateContact } from "../dtos/create-contact.dto";
import { CreateDesignOfficeDto } from "../dtos/create-design-office.dto";
import { Job } from "../enum/job.enum";
import useAuth from "../hooks/useAuth";
import { Building } from "../models/building.model";
import { Contact } from "../models/contact.model";
import { DesignOffice } from "../models/design-office.model";
import AddressComponent from "./AddressComponent";
import ContactInformations from "./ContactInformations";
import DesignOfficeComponent from "./DesignOfficeComponent";

interface ContactComponentProps {
    buildingId: number;
    job?: Job;
    isBuildingPhoto: boolean;
    isDesignOffice: boolean;
    isContractingAuthority: boolean
}

const ContactComponent: React.FC<ContactComponentProps> = ({buildingId, job, isBuildingPhoto, isDesignOffice, isContractingAuthority}) => {
    const { person } = useAuth();
    const headers = {'Authorization': 'Bearer ' + person?.accessToken};

    const [openDialogContactInformations, setOpenDialogContactInformations] = useState<boolean>(false);
    const [openDialogAddressBuilding, setOpenDialogAddressBuilding] = useState<boolean>(false);
    const [openDialogDesignOffice, setOpenDialogDesignOffice] = useState<boolean>(false);
    const [openDialogPhotoBuilding, setOpenDialogPhotoBuilding] = useState<boolean>(false);

    const handleCloseDialogContactInformations = () => {
        setOpenDialogContactInformations(false);
    }

    const handleCloseDialogAddressBuilding = () => {
        setOpenDialogAddressBuilding(false);
    }

    const handleCloseDialogDesignOffice = () => {
        setOpenDialogDesignOffice(false);
    }

    const handleCloseDialogPhotoBuilding = () => {
        setOpenDialogPhotoBuilding(false);
    }

    const [contact, setContact] = useState<Contact>();
    const [building, setBuilding] = useState<Building>();
    const [contactAfterPost, setContactAfterPost] = useState<Contact>();
    const [addressBuildingAfterPost, setAddressBuildingAfterPost] = useState<Address>();
    const [designOfficeAfterPost, setDesignOfficeAfterPost] = useState<DesignOffice>();

    const [urlPhotoForDisplay, setUrlPhotoForDisplay] = useState<string>();

    const [addressBuilding, setAddressBuilding] = useState<CreateAddressDto>({
        number: '',
        street: '',
        zipCode: '',
        city: '',
        country: ''
    });

    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

    const handleChangeAddressBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAddressBuilding(({...addressBuilding, [name]: value}))
    }
    
    const handleSaveAddressBuilding = () => {
        axios.post<Address>("/address", addressBuilding, {headers})
            .then((response) => {
                console.log(response)
                setAddressBuildingAfterPost(response.data)
                setOpenDialogAddressBuilding(false);
                window.location.reload();
            })
            .catch((error) => console.log(error));
    }

    const [addressDesignOffice, setAddressDesignOffice] = useState<CreateAddressDto>({
        number: '',
        street: '',
        zipCode: '',
        city: '',
        country: ''
    });

    const [infoDesignOffice, setInfoDesignOffice] = useState<CreateDesignOfficeDto>({
        businessName: '',
        email: '',
        phoneNumber: '',
        address: addressDesignOffice
      })
    
    const handleChangeAddressDesignOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAddressDesignOffice(({...addressDesignOffice, [name]: value}))
        setInfoDesignOffice({
        ...infoDesignOffice,
        address: {
            ...infoDesignOffice.address,
            [name]: value
        }
        })
    }

    const handleChangeDesignOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setInfoDesignOffice(({...infoDesignOffice, [name]: value}))
    }

    const handleSaveDesignOffice = () => {
        console.log(infoDesignOffice)
        axios.post<DesignOffice>("/design-office", infoDesignOffice, {headers})
        .then((response) => {
            console.log(response)
            setDesignOfficeAfterPost(response.data)
            setOpenDialogDesignOffice(false);
        })
        .catch((error) => console.log(error));
    }
    
    const handleSaveContactInformations = () => {
        const contact: CreateContact = job === Job.FINANCIAL_ENGINEER ? contactInformationsFinancialEngineer : contactInformationsDelegateContractingAuthority;
        console.log(contact);
        axios.post<Contact>(`/contact/${buildingId}`, contact, {headers})
            .then((response) => {
                console.log(response)
                setContactAfterPost(response.data)
                setOpenDialogContactInformations(false);
            })
            .catch((error) => console.log(error))
    }

    const [addressFinancialEngineer, setAddressFinancialEngineer] = useState<CreateAddressDto>({
        number: '',
        street: '',
        zipCode: '',
        city: '',
        country: ''
    });

    const [contactInformationsFinancialEngineer, setContactInformationsFinancialEngineer] = useState<CreateContact>({
        businessName: '',
        email: '',
        phoneNumber: '',
        job: Job.FINANCIAL_ENGINEER,
        address: addressFinancialEngineer,
    })

    const handleChangeAddressFinancialEngineer = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAddressFinancialEngineer(({...addressFinancialEngineer, [name]: value}))
        setContactInformationsFinancialEngineer({
            ...contactInformationsFinancialEngineer,
            address: {
                ...contactInformationsFinancialEngineer.address,
                [name]: value
            }
        })
    }

    const handleChangeContactInformationsFinancialEngineer = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setContactInformationsFinancialEngineer(({...contactInformationsFinancialEngineer, [name]: value}));
    }

    const [addressDelegateContractingAuthority, setAddressDelegateContractingAuthority] = useState<CreateAddressDto>({
        number: '',
        street: '',
        zipCode: '',
        city: '',
        country: ''
    });
    
    const [contactInformationsDelegateContractingAuthority, setContactInformationsDelegateContractingAuthority] = useState<CreateContact>({
        businessName: '',
        email: '',
        phoneNumber: '',
        job: Job.DELEGATE_CONTRACTING_AUTHORITY,
        address: addressDelegateContractingAuthority,
    })

    const handleChangeAddressDelegateContractingAuthority = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAddressDelegateContractingAuthority(({...addressDelegateContractingAuthority, [name]: value}))
        setContactInformationsDelegateContractingAuthority({
            ...contactInformationsDelegateContractingAuthority,
            address: {
                ...contactInformationsDelegateContractingAuthority.address,
                [name]: value
            }
        })
    }

    const handleChangeContactDelegateContractingAuthority = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setContactInformationsDelegateContractingAuthority(({...contactInformationsDelegateContractingAuthority, [name]: value}));
    }

    const handleOpenDialog = () => {
        if (job !== undefined && isDesignOffice === false) {
            setOpenDialogContactInformations(true);
        } else if (isDesignOffice === true) {
            setOpenDialogDesignOffice(true);
        } else {
            setOpenDialogAddressBuilding(true);
        }
    }
    
    useEffect(() => {
        job !== undefined ? 
        axios.get<Contact>(`/contact/by-building-and-job?buildingId=${buildingId}&job=${job}`, {headers})
            .then((response) => {
                console.log(response)
                if (response.data.id !== undefined) {
                    if (job === Job.FINANCIAL_ENGINEER) {
                        setAddressFinancialEngineer(response.data.address);
                        setContactInformationsFinancialEngineer(response.data)
                    } else if (job === Job.DELEGATE_CONTRACTING_AUTHORITY) {
                        setAddressDelegateContractingAuthority(response.data.address);
                        setContactInformationsDelegateContractingAuthority(response.data)
                    }
                }
                setContact(response.data)
            })
            .catch((error) => console.log(error))
        : axios.get<Building>(`/building/${buildingId}`, {headers})
            .then((response) => {
                console.log(response)

                const tempBuilding: Building = response.data

                setBuilding(tempBuilding)

                getUrlPhotoForDisplay(tempBuilding);

                setAddressBuilding(response.data.address);
                setInfoDesignOffice(response.data.designOffice);
                setAddressDesignOffice(response.data.designOffice.address);
            })
            .catch((error) => console.log(error))
    }, [contactAfterPost, addressBuildingAfterPost, designOfficeAfterPost])

    const getUrlPhotoForDisplay = (building: Building) => {
        if (building.photoPath !== '') {
            axios.get(`/file-upload?fileName=${building.photoPath}`, {headers})
            .then((response) => {
              console.log(response)
              setUrlPhotoForDisplay(response.data)
            })
            .catch((error) => console.log(error))
        }
    }

    const openDialogEditContact = (building: Building | undefined, contact: Contact | undefined) => {
        if (building !== undefined && !isDesignOffice) {
            console.log(building)
            setOpenDialogAddressBuilding(true);
        } else if (building !== undefined && isDesignOffice) {
            console.log(building)
            setOpenDialogDesignOffice(true);
        } else if (contact !== undefined) {
            console.log(contact)
            setOpenDialogContactInformations(true);
        }
    }

    const saveContactIsDisabled = () => {
        if (job === Job.FINANCIAL_ENGINEER) {
            return isContactDisabled(contactInformationsFinancialEngineer);
        } else if (job === Job.DELEGATE_CONTRACTING_AUTHORITY) {
            return isContactDisabled(contactInformationsDelegateContractingAuthority);
        }
    }

    const isContactDisabled = (contact: CreateContact) => {
        if (contact.businessName?.length === 0 || contact.email.length === 0 || contact.phoneNumber.length === 0 || saveAddressIsDisabled(contact.address)) {
            return true;
        }
        return false;
    }

    const saveDesignOfficeIsDisabled = (designOffice: CreateDesignOfficeDto) => {
        if (designOffice.businessName.length === 0 || designOffice.email.length === 0 || designOffice.phoneNumber.length === 0 || saveAddressIsDisabled(designOffice.address)) {
            return true;
        }
        return false;
    }

    
    const saveAddressIsDisabled = (address: CreateAddressDto) => {
        if (address.number.length === 0 || address.street.length === 0 || address.zipCode.length === 0 || address.city.length === 0 || address.country.length === 0) {
            return true;
        }
        return false;
    }

    const openDialogEditPhotoBuilding = () => {
        setOpenDialogPhotoBuilding(true);
    }

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [namePreviousImage, setNamePreviousImage] = useState<string>();
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Récupérer le fichier sélectionné par l'utilisateur
        const file = event.target.files && event.target.files[0];
      
        // Mettre à jour l'état avec le fichier sélectionné
        setSelectedFile(file || null);
  
        if (building?.photoPath && building?.photoPath !== '') {
            setNamePreviousImage(building?.photoPath)
        }
    };
  
    const handleUpload = () => {
      if (selectedFile) {
          const formData = new FormData();
          const file = selectedFile;
          formData.append("file", file);

          setIsLoadingImage(true);
  
          axios.post('/file-upload/image', formData, {headers})
            .then((response) => {
                console.log(response)
                setBuilding({...building!, photoPath: response.data})
                deleteImageInMinio(namePreviousImage);
  
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Succès",
                  text: 'L\'image a bien été téléversée',
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true
                });
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoadingImage(false))
      }
    };

    const handleSavePhotoBuilding = () => {
        axios.put<Building>(`/building/edit-path/${building?.id}`, {
            photoPath: building?.photoPath
        }, {headers})
            .then((response) => {
                console.log(response)
                setOpenDialogPhotoBuilding(false);
                getUrlPhotoForDisplay(response.data)
            })
            .catch((error) => console.log(error))
    }

    const handleDeletePhotoBuilding = () => {
        deleteImageInMinio(building?.photoPath);
        axios.put<Building>(`/building/edit-path/${building?.id}`, {
            photoPath: ''
        }, {headers})
            .then((response) => {
                console.log(response)
                setOpenDialogPhotoBuilding(false);
            })
            .catch((error) => console.log(error))
        setBuilding({...building!, photoPath: undefined})
        setOpenDialogPhotoBuilding(false);
        setUrlPhotoForDisplay('');
    }

    const deleteImageInMinio = (photoPath: string | undefined) => {
        if (photoPath !== undefined && photoPath !== '') {
          axios.delete(`/file-upload?fileName=${photoPath}`, {headers})
              .then((response) => console.log(response))
              .catch((error) => console.log(error))
        }
      }

    return (
        <Box>
            {contact?.id !== undefined ? (
                <CardActionArea onClick={() => openDialogEditContact(building, contact)}>
                    <Box className="flex flex-col items-center">
                        <Typography variant="subtitle2">{contact.businessName}</Typography>
                        <Typography variant="subtitle2">{contact.phoneNumber}</Typography>
                        <Typography variant="subtitle2">{contact.email}</Typography>
                    </Box>
                </CardActionArea>
            ) : isDesignOffice === true && building?.designOffice !== undefined ? (
                <CardActionArea onClick={() => openDialogEditContact(building, contact)}>
                    <Box className="flex flex-col items-center">
                        <Typography variant="subtitle2">{building.designOffice.businessName}</Typography>
                        <Typography variant="subtitle2">{building.designOffice.phoneNumber}</Typography>
                        <Typography variant="subtitle2">{building.designOffice.email}</Typography>
                    </Box>
                </CardActionArea>
            ) : building?.id !== undefined && isBuildingPhoto ? (
                <CardActionArea onClick={openDialogEditPhotoBuilding}>
                    <Box className="flex flex-col items-center">
                    {urlPhotoForDisplay && urlPhotoForDisplay !== '' ? (
                        <img
                            srcSet={`${urlPhotoForDisplay}`}
                            src={`${urlPhotoForDisplay}`}
                            alt='Immeuble'
                            width={120}
                            height={100}
                            loading="lazy"
                        />
                        ) : (
                        <ApartmentIcon style={{ fontSize: 40 }} />
                    )}
                    </Box>
                </CardActionArea>
            ) : building?.id !== undefined && !isContractingAuthority ? (
                <CardActionArea onClick={() => openDialogEditContact(building, contact)}>
                    <Box className="flex flex-col items-center">
                        <Typography variant="subtitle2">{building.address.number} {building.address.street}</Typography>
                        <Typography variant="subtitle2">{building.address.zipCode} {building.address.city}</Typography>
                        <Typography variant="subtitle2">{building.address.country}</Typography>
                    </Box>
                </CardActionArea>
            ) : building?.id !== undefined && isContractingAuthority ? (
                <Box className="flex flex-col items-center">
                    <Typography variant="subtitle2">SDC {building.address.number} {building.address.street}</Typography>
                    <Typography variant="subtitle2">{building.address.zipCode} {building.address.city}</Typography>
                    <Typography variant="subtitle2">{building.address.country}</Typography>
                </Box>
            ) : (
                <Box className="flex justify-center">
                    <Button variant="contained" className="!bg-black" onClick={() => handleOpenDialog()}>
                        Créer le contact
                    </Button>
                </Box>
            )}

            <Dialog open={openDialogContactInformations} onClose={handleCloseDialogContactInformations} className='w-full'>
                <DialogTitle className='!text-2xl !font-bold'>Ajouter un {job === Job.FINANCIAL_ENGINEER ? 'ingénieur financier' : 'maître d\'ouvrage délégué'}</DialogTitle>
                <DialogContent className='flex space-x-4'>
                    {job === Job.FINANCIAL_ENGINEER ? (
                        <Box className='w-full space-y-2'>
                            <DialogContentText className='text-center !font-bold'>Informations de société</DialogContentText>
                            <ContactInformations contactInformations={contactInformationsFinancialEngineer} handleChangeContactInformations={handleChangeContactInformationsFinancialEngineer} />
                            <DialogContentText className='text-center !font-bold'>Informations sur l'adresse de l'ingénieur financier</DialogContentText>
                            <AddressComponent address={addressFinancialEngineer} handleChangeAddress={handleChangeAddressFinancialEngineer} />
                        </Box>
                    ) : (
                        <Box className='w-full space-y-2'>
                            <DialogContentText className='text-center !font-bold'>Informations de société</DialogContentText>
                            <ContactInformations contactInformations={contactInformationsDelegateContractingAuthority} handleChangeContactInformations={handleChangeContactDelegateContractingAuthority} />
                            <DialogContentText className='text-center !font-bold'>Informations sur l'adresse du maitre d'ouvrage délégué d'étude</DialogContentText>
                            <AddressComponent address={addressDelegateContractingAuthority} handleChangeAddress={handleChangeAddressDelegateContractingAuthority} />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                    className="!bg-black"
                    variant="contained"
                    size="medium"
                    onClick={handleCloseDialogContactInformations}
                    >
                        Annuler
                    </Button>
                    <Button              
                    className={saveContactIsDisabled() ? '!bg-grey' : '!bg-black'}
                    variant="contained"
                    size="medium" 
                    onClick={handleSaveContactInformations}
                    disabled={saveContactIsDisabled()}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogAddressBuilding} onClose={handleCloseDialogAddressBuilding} className='w-full'>
                <DialogTitle className='!text-2xl !font-bold'>Modifier l'adresse du bureau d'étude</DialogTitle>
                <DialogContent className='flex space-x-4'>
                    <Box className='w-full space-y-2'>
                        <DialogContentText className='text-center !font-bold'>Informations sur l'adresse</DialogContentText>
                        <AddressComponent address={addressBuilding} handleChangeAddress={handleChangeAddressBuilding}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                    className="!bg-black"
                    variant="contained"
                    size="medium"
                    onClick={handleCloseDialogAddressBuilding}
                    >
                        Annuler
                    </Button>
                    <Button              
                    className={saveAddressIsDisabled(addressBuilding) ? '!bg-grey' : '!bg-black'}
                    variant="contained"
                    size="medium" 
                    onClick={handleSaveAddressBuilding}
                    disabled={saveAddressIsDisabled(addressBuilding)}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogDesignOffice} onClose={handleCloseDialogDesignOffice} className='w-full'>
                <DialogTitle className='!text-2xl !font-bold'>Modifier les informations du bureau d'étude</DialogTitle>
                <DialogContent className='flex space-x-4'>
                    <Box className='w-full space-y-2'>
                        <DialogContentText className='text-center !font-bold'>Informations du bureau d'étude</DialogContentText>
                        <DesignOfficeComponent designOffice={infoDesignOffice} handleChangeDesignOffice={handleChangeDesignOffice} />
                        <DialogContentText className='text-center !font-bold'>Informations sur l'adresse du bureau d'étude</DialogContentText>
                        <AddressComponent address={addressDesignOffice} handleChangeAddress={handleChangeAddressDesignOffice} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                    className="!bg-black"
                    variant="contained"
                    size="medium"
                    onClick={handleCloseDialogDesignOffice}
                    >
                        Annuler
                    </Button>
                    <Button              
                    className={saveDesignOfficeIsDisabled(infoDesignOffice) ? '!bg-grey' : '!bg-black'}
                    variant="contained"
                    size="medium" 
                    onClick={handleSaveDesignOffice}
                    disabled={saveDesignOfficeIsDisabled(infoDesignOffice)}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogPhotoBuilding} onClose={handleCloseDialogPhotoBuilding} className='w-full'>
                <DialogTitle className='!text-2xl !font-bold'>Modifier la photo de l'immeuble</DialogTitle>
                <DialogContent className='flex space-x-4'>
                <Box className="flex flex-col space-y-3 justify-center items-center w-full">
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*"
                        />  
                        <Button variant="contained" color="primary" onClick={() => handleUpload()}>
                            Téléverser
                        </Button>

                        {isLoadingImage ? (
                            <CircularProgress/>
                        ) : null}

                        {building?.photoPath && building?.photoPath !== '' ? (
                            <Typography variant="subtitle2" className="text-justify" color="grey">Il existe déjà une photo pour cet immeuble, téléverser en une nouvelle pour la remplacer</Typography>
                        ) : (<Typography></Typography>)}
                </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                    className="!bg-black"
                    variant="contained"
                    size="medium"
                    onClick={handleCloseDialogPhotoBuilding}
                    >
                        Annuler
                    </Button>
                    {building?.photoPath && building?.photoPath !== '' ? (
                        <Button 
                        className="!bg-black"
                        variant="contained"
                        size="medium"
                        onClick={handleDeletePhotoBuilding}
                        >
                            Effacer l'image présente
                        </Button>) : (
                        <Box></Box>
                    )}
                    <Button              
                    className={isLoadingImage ? '!bg-grey' : '!bg-black'}
                    variant="contained"
                    size="medium"
                    disabled={isLoadingImage}
                    onClick={handleSavePhotoBuilding}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ContactComponent;