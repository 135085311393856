import { FormControl, TextField } from "@mui/material";
import { CreateContactInformations } from "../dtos/create-contact-informations";

interface ContactInformationsProps {
    contactInformations: CreateContactInformations,
    handleChangeContactInformations: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactInformations: React.FC<ContactInformationsProps> = ({contactInformations, handleChangeContactInformations}) => {
    return (
        <FormControl className="flex flex-col justify-center items-center w-full space-y-2">
            <TextField 
                id="businessName"
                name="businessName"
                label="Nom"
                type="text"
                variant="outlined"
                size='small'
                value={contactInformations.businessName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeContactInformations(event)}
            />
            <TextField 
                id="email"
                name='email'
                label="Email"
                type="email"
                variant="outlined"
                size='small'
                value={contactInformations.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeContactInformations(event)}
            />
            <TextField 
                id="phoneNumber"
                name="phoneNumber"
                label="Numéro de téléphone"
                type="tel"
                variant="outlined"
                size='small'
                value={contactInformations.phoneNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeContactInformations(event)}
            />
        </FormControl>
    )
}

export default ContactInformations;