import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Backdrop, Box, Button, CardActionArea, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../axios/axios';
import ContactComponent from '../components/ContactComponent';
import { CreateRecordDto } from '../dtos/create-record.dto';
import { UpdateNameRecordDto } from '../dtos/update-record.dto';
import { Job } from '../enum/job.enum';
import { RecordType } from '../enum/record-type.enum';
import { State } from '../enum/state.enum';
import useAuth from '../hooks/useAuth';
import { Record } from '../models/record.model';
import JSZip from 'jszip';
import { Contact } from '../models/contact.model';
import { Building } from '../models/building.model';
import { UpdateNameBuildingdDto } from '../dtos/update-building.dto';


const BuildingPage = () => {
    let { buildingId } = useParams();
    const { person } = useAuth();
    const headers = {'Authorization': 'Bearer ' + person?.accessToken};
    const navigate = useNavigate();

    const [building, setBuilding] = useState<Building>();

    const [updateBuilding, setUpdateBuilding] = useState<UpdateNameBuildingdDto>({
        name: '',
    });

    const [recordsList, setRecordsList] = useState<Record[]>([]);
    const recordTypeValues = Object.values(RecordType);

    const [newRecord, setNewRecord] = useState<CreateRecordDto>({
        name: '',
        recordType: RecordType.ASCENSEURS,
    });

    const [updateRecord, setUpdateRecord] = useState<UpdateNameRecordDto>({
        name: '',
    });

    const [recordsListToSendToEngineer, setRecordsListToSendToEngineer] = useState<Record[]>([]);

    const [openDialogUpdateBuildingName, setOpenDialogUpdateBuildingName] = useState<boolean>(false);

    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const [recordIdToUpdateOrDelete, setRecordIdToUpdateDelete] = useState<number | undefined>();

    const [openDialogSendEngineer, setOpenDialogSendEngineer] = useState<boolean>(false);

    const [openDialogUpdateRecordName, setOpenDialogUpdateRecordName] = useState<boolean>(false);

    const [openDialogMultipleDownload, setOpenDialogMultipleDownload] = useState<boolean>(false);
    const [recordsListForMultipleDownload, setRecordsListForMultipleDownlaod] = useState<Record[]>([]);

    const [openDialogDeleteBuilding, setOpenDialogDeleteBuilding] = useState<boolean>(false);

    const [isLoadingTitlePage, setIsLoadingTitlePage] = useState<boolean>(false);
    const [isLoadingMergeDocx, setIsLoadingMergeDocx] = useState<boolean>(false);


    useEffect(() => {
        getBuilding();
        getRecords();
    }, [])

    const getBuilding = () => {
        axios.get<Building>(`/building/${buildingId}`, {headers})
            .then((response) => {
                console.log(response);
                if (response.data.deletedAt === null) {
                    setBuilding(response.data);
                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erreur",
                        text: "Cet immeuble a été supprimé",
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    });
                    navigate('/home')
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getRecords = () => {
        axios.get<Record[]>(`/record/building/${buildingId}`, {headers})
        .then((response) => {
            console.log(response)
            setRecordsList(response.data.filter((record) => record.deletedAt == null));
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const publishRecord = () => {
        axios.post(`/record/${buildingId}`, newRecord, {headers})
            .then((response) => {
                setRecordsList(recordsList => [...recordsList, response.data]);
                setNewRecord({        
                    name: '',
                    recordType: RecordType.ASCENSEURS
                });
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleChangeUpdateBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setUpdateBuilding(({...updateBuilding, [name]: value}));
    }

    const handleOpenDialogUpdateBuilding = () => {
        setUpdateBuilding(({...updateBuilding, name: building?.name ?? ''}))
        setOpenDialogUpdateBuildingName(true);
    }

    const saveUpdateBuilding = () => {
        axios.put<Building>(`building/edit-name/${buildingId}`, updateBuilding, {headers})
        .then((response) => {
            console.log(response)
            setOpenDialogUpdateBuildingName(false)
            setUpdateBuilding({
                name: ''
            })
            getBuilding();
        })
        .catch((error) => console.log(error))
    }

    const handleChangeSelect = (event: SelectChangeEvent) => {
        setNewRecord(({...newRecord, recordType: event.target.value as RecordType}))
    }

    const handleChangeRecordName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setNewRecord(({...newRecord, [name]: value}));
    }

    const handleChangeUpdateRecord = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setUpdateRecord(({...updateRecord, [name]: value}));
    }
    
    const handleOpenDialogUpdateRecord = (id: number, name: string) => {
        setRecordIdToUpdateDelete(id);
        setUpdateRecord(({...updateRecord, name: name}))
        setOpenDialogUpdateRecordName(true);
    }

    const saveUpdateRecord = (id: number| undefined) => {
        axios.put<Record>(`record/edit-name/${id}`, updateRecord, {headers})
            .then((response) => {
                console.log(response)
                setOpenDialogUpdateRecordName(false)
                setUpdateRecord({
                    name: ''
                })
                getRecords();
            })
            .catch((error) => console.log(error))
    }

    const handleOpenDialogDelete = (id: number) => {
        setRecordIdToUpdateDelete(id);
        setOpenDialogDelete(true);
    }

    const deleteRecord = (id: number | undefined) => {
        console.log(id)
        axios.get(`record/${id}`, {headers})
            .then((response) => {
                console.log(response.data)
                axios.delete(`file-upload?fileName=${response.data.path}`, {headers})
                    .then((response) => {
                        console.log(response)
                        setOpenDialogDelete(false);
                        axios.put(`record/edit-deleted-at/${id}`, {}, {headers})
                            .then((response) => {
                                console.log(response);
                                getRecords();
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleOpenDialogSendToEngineer = () => {
        axios.get<Contact>(`/contact/by-building-and-job?buildingId=${buildingId}&job=${Job.FINANCIAL_ENGINEER}`, {headers})
            .then((response) => {
                console.log(response)
                if (response.data.id !== undefined) {
                    setRecordsListToSendToEngineer([]);
                    setOpenDialogSendEngineer(true);
                } else {                
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erreur",
                        text: "Renseigner les informations de l'ingénieur financier",
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleChangeSwitchSendToEngineer = (record: Record) => {
        const newRecordsListToSendToEngineer: Record[] = [...recordsListToSendToEngineer];
        if (newRecordsListToSendToEngineer.filter((recordFilter) => recordFilter.id === record.id).length !== 0) {
            const indexElementToDelete = newRecordsListToSendToEngineer.findIndex((recordFind) => recordFind.id === record.id)
            newRecordsListToSendToEngineer.splice(indexElementToDelete, 1);
        } else {
            newRecordsListToSendToEngineer.push(record);
        }
        setRecordsListToSendToEngineer(newRecordsListToSendToEngineer);
    }

    const sendMail = () => {
        console.log(recordsListToSendToEngineer)
        axios.get(`/building/${buildingId}`, {headers})
            .then((response) => {
                console.log(response)
                axios.post<void>('/mail', recordsListToSendToEngineer, {headers})
                    .then((response) => {
                        console.log(response)
                        recordsListToSendToEngineer.forEach((recordToSendToEngineer) => {
                            axios.put(`record/edit-state/${recordToSendToEngineer.id}`, {
                                state: State.SEND_ENGINEER
                            }, {headers})
                                .then((response) => console.log(response))
                                .catch((error) => console.log(error));
                        })
                        window.location.reload();
                    })
                    .catch((error) => console.log(error))
            })
            .catch((error) => console.log(error))
        
        setOpenDialogSendEngineer(false);
    }

    const handleDownloadRecord = (record: Record) => {
        if (record.path !== undefined) {
            axios.get<Record>(`/record/${record.id}`, {headers})
            .then((response) => {
                const pathRecordToDownload: string = response.data.path!;
                axios.get(`/file-upload?fileName=${pathRecordToDownload}`, {headers})
                .then((response) => {
                    fetch(response.data)
                        .then(res => res.blob())
                            .then((blob) => {
                                FileSaver.saveAs(blob, `${building?.name}_FICHE_${record.recordType.toUpperCase()}_${record.name}.docx`);
                            })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => { 
                    console.log(error);
                })
            })     
            .catch((error) => { 
                console.log(error);
            })       
        }
    }

    const goUpRecord = (recordId: number) => {
        const index = recordsList.findIndex((record) => record.id === recordId);
        if (index > 0) {
          const newRecordsList = [...recordsList];
          newRecordsList[index].sortNumber = index - 1;
          newRecordsList[index - 1].sortNumber = index;
          [newRecordsList[index - 1], newRecordsList[index]] = [
            newRecordsList[index],
            newRecordsList[index - 1],
          ];
          setRecordsList(newRecordsList);
          saveSortNumberOfRecords();
        }
    }

    const goDownRecord = (recordId: number) => {
        const index = recordsList.findIndex((record) => record.id === recordId);
        if (index < recordsList.length - 1) {
          const newRecordsList = [...recordsList];
          newRecordsList[index].sortNumber = index + 1;
          newRecordsList[index + 1].sortNumber = index;
          [newRecordsList[index], newRecordsList[index + 1]] = [
            newRecordsList[index + 1],
            newRecordsList[index],
          ];
          setRecordsList(newRecordsList);
          saveSortNumberOfRecords();
        }
    }

    const saveSortNumberOfRecords = () => {
        axios.put<Record[]>('/record/edit-sort-number', recordsList, {headers})
            .then((response) => {
                console.log(response)
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Succès",
                    text: 'Tri des fiches sauvegardés',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
            })
            .catch((error) => console.log(error))
    }

    const generateTitlePage = () => {
        setIsLoadingTitlePage(true);

        axios.get<Contact[]>(`/contact/by-building/${buildingId}`, {headers})
            .then((response) => {
                console.log(response)
                if (response.data.length >= 2) {
                    axios.get(`/generate-docx/title-page/${buildingId}`, {headers})
                        .then((response) => {
                            const pathTitlePage = response.data;
                            axios.get(`/file-upload?fileName=${pathTitlePage}`, {headers})
                            .then((response) => {
                                fetch(response.data)
                                    .then(res => res.blob())
                                        .then((blob) => {
                                            FileSaver.saveAs(blob, `page_garde.docx`);
                                            axios.delete(`/file-upload?fileName=${pathTitlePage}`, {headers})
                                                .then((response) => console.log(response))
                                                .catch((error) => console.log(error))
                                        })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            })
                            .catch((error) => { 
                                console.log(error);
                            })
                        })
                        .catch((error) => console.log(error))
                } else {                
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erreur",
                        text: "Renseigner toutes les informations de contact",
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setIsLoadingTitlePage(false))
    }

    const handleOpenDialogMultipleDownload = () => {
        getRecords();
        setRecordsListForMultipleDownlaod([]);
        setOpenDialogMultipleDownload(true);
    }

    const handleChangeSwitchMultipleDownload = (record: Record) => {
        const newRecordsListForMultipleDownload: Record[] = [...recordsListForMultipleDownload];
        if (newRecordsListForMultipleDownload.filter((recordFilter) => recordFilter.id === record.id).length !== 0) {
            const indexElementToDelete = newRecordsListForMultipleDownload.findIndex((recordFind) => recordFind.id === record.id)
            newRecordsListForMultipleDownload.splice(indexElementToDelete, 1);
        } else {
            newRecordsListForMultipleDownload.push(record);
        }
        setRecordsListForMultipleDownlaod(newRecordsListForMultipleDownload);
    }

    const downloadFile = async (record: Record) => {
        const response = await axios.get(`/file-upload?fileName=${record.path}`, {headers});
        const res = await fetch(response.data);
        return await res.blob();
    }

    const multipleDownload = async () => {
        recordsListForMultipleDownload.sort((a, b) => a.sortNumber - b.sortNumber);

        const zip = new JSZip();
        
        const downloadPromises = recordsListForMultipleDownload.map(record => downloadFile(record));
        
        Promise.all(downloadPromises)
            .then(blobs => {
                blobs.forEach((blob, index) => {
                    const currentRecordForMultipleDownload = recordsListForMultipleDownload[index];
                    zip.file(`FICHE_${currentRecordForMultipleDownload.recordType.toUpperCase()}_${currentRecordForMultipleDownload.name}.docx`, blob);
                });

                return zip.generateAsync({ type: "blob" });
            })
            .then((zipBlob) => {
                const nameZip = building !== undefined ? building.name + '_' + building.address.number + '-' + building.address.street.toUpperCase() + '_FICHES_DTG' : 'FICHES_DTG';
                FileSaver.saveAs(zipBlob, nameZip);
                setOpenDialogMultipleDownload(false);
            })
            .catch(error => {
                console.error('Une erreur s\'est produite :', error);
            });
    }

    const selectAllForDowload = () => {
        const allRecordsRdyForDownload = recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.COMPLETED)
        const newRecordsListForMultipleDownload: Record[] = recordsListForMultipleDownload.length === allRecordsRdyForDownload.length
            ? []
            : [...allRecordsRdyForDownload]; 
        setRecordsListForMultipleDownlaod(newRecordsListForMultipleDownload);
    }

    const selectAllToSendToEngineer = () => {
        const allRecordsRdyToSendToEngineer = recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.SEND_ENGINEER)
        const newRecordsListToSendToEngineer: Record[] = recordsListToSendToEngineer.length === allRecordsRdyToSendToEngineer.length
            ? []
            : [...allRecordsRdyToSendToEngineer]; 
        setRecordsListToSendToEngineer(newRecordsListToSendToEngineer);
    }

    const deleteBuilding = () => {
        axios.put(`/building/edit-deleted-at/${buildingId}`, {}, {headers})
            .then((response) => {
                console.log(response)
                navigate(`/home`)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleMerge = async () => {
        setIsLoadingMergeDocx(true);
        await axios.get(`/generate-docx/merge-building/${buildingId}`, {headers})
        .then((response) => {
            fetch(response.data)
                .then(res => res.blob())
                    .then((blob) => {
                        FileSaver.saveAs(blob, `${building?.name}_FICHE_GLOBALE.docx`);
                    })
                .catch((error) => {
                    console.log(error)
                })
        })
        .catch((error) => { 
            console.log(error);
        })
        .finally(() => setIsLoadingMergeDocx(false))
    }

    return (
        <Box className="flex flex-col w-full mt-3 space-y-4">
            <Box className="flex items-center px-5 py-3 space-x-10 bg-white">
                <Box className="flex items-center justify-center w-full space-x-1">
                    <Typography variant="h6" className='!font-bold text-center'>
                        {building?.name}
                    </Typography>
                    <EditIcon style={{ fontSize: 20 }} onClick={() => handleOpenDialogUpdateBuilding()}/>
                </Box>
            </Box>
            <Box className="flex items-center px-5 py-3 space-x-10 bg-white">
                <ContactComponent buildingId={parseInt(buildingId!)} isBuildingPhoto={true} isDesignOffice={false} isContractingAuthority={false} />
                <Box className="flex justify-between w-full">
                    <Box className="flex flex-col">
                        <Typography variant="subtitle1" className='!font-bold text-center'>
                            Adresse
                        </Typography>
                        <ContactComponent buildingId={parseInt(buildingId!)} isBuildingPhoto={false} isDesignOffice={false} isContractingAuthority={false} />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className='!font-bold text-center'>
                            Bureau d'étude
                        </Typography>
                        <ContactComponent buildingId={parseInt(buildingId!)} isBuildingPhoto={false} isDesignOffice={true} isContractingAuthority={false} />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className='!font-bold text-center'>
                            Ingénieur financier - AMO
                        </Typography>
                        <ContactComponent buildingId={parseInt(buildingId!)} job={Job.FINANCIAL_ENGINEER} isBuildingPhoto={false} isDesignOffice={false} isContractingAuthority={false} />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className='!font-bold text-center'>
                            Maître d'ouvrage
                        </Typography>
                        <ContactComponent buildingId={parseInt(buildingId!)} isBuildingPhoto={false} isDesignOffice={false} isContractingAuthority={true} />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className='!font-bold text-center'>
                            Maître d'ouvrage délégué
                        </Typography>
                        <ContactComponent buildingId={parseInt(buildingId!)} job={Job.DELEGATE_CONTRACTING_AUTHORITY} isBuildingPhoto={false} isDesignOffice={false} isContractingAuthority={false} />
                    </Box>
                </Box>
            </Box>
            <Box className="flex flex-col p-3 space-y-6 bg-white">
                <Box className="flex w-full">
                    <Box className="flex items-center w-1/4">
                        <Typography variant="h5" className="!font-bold">Gestion des fiches</Typography>
                    </Box>
                    <Box className="flex justify-end w-full space-x-6">
                        <Button variant="contained" size='medium'className="!bg-black" onClick={handleOpenDialogSendToEngineer}>
                            Envoyer les fiches à l'ingénieur
                        </Button>
                        <Button variant="contained" size='medium'className="!bg-black" onClick={generateTitlePage}>
                            Générer la page de garde
                        </Button>
                        <Button variant="contained" size='medium'className="!bg-black" onClick={handleOpenDialogMultipleDownload}>
                            Téléchargement multiple
                        </Button>
                        <Button variant="contained" size='medium'className="!bg-black" onClick={handleMerge}>
                            Fusionner
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="w-1/8 !text-center">Position</TableCell>
                                    <TableCell className="w-1/8 !text-center">Date de création</TableCell>
                                    <TableCell className="w-1/8 !text-center">Nom</TableCell>
                                    <TableCell className="w-1/8 !text-center">Type</TableCell>
                                    <TableCell className="w-1/8 !text-center">Etat</TableCell>
                                    <TableCell className="w-1/8 !text-center">Gestion</TableCell>
                                    <TableCell className="w-1/8 !text-center">Formulaire</TableCell>
                                    <TableCell className="w-1/8 !text-center">Supprimer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recordsList?.filter((record) => record.deletedAt == null).map((record) => (
                                    <TableRow key={record.id}>
                                        <TableCell className="!text-center">
                                            <IconButton onClick={() => goUpRecord(record.id)} disabled={record.sortNumber === 0}>
                                                <KeyboardDoubleArrowUpIcon className="text-black" />
                                            </IconButton>
                                            <IconButton onClick={() => goDownRecord(record.id)} disabled={record.sortNumber === recordsList.length - 1}>
                                                <KeyboardDoubleArrowDownIcon className="text-black" />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className="!text-center">{dayjs(record.createdAt).format("DD/MM/YYYY à HH:mm")}</TableCell>
                                        <TableCell className="!text-center">
                                            <CardActionArea onClick={() => handleOpenDialogUpdateRecord(record.id, record.name)}>
                                                {record.name}
                                            </CardActionArea>
                                        </TableCell>
                                        <TableCell className="!text-center">{record.recordType}</TableCell>
                                        {record.state === State.EMPTY ? (
                                            <TableCell className="!text-center !text-red-600"><CircleIcon sx={{ fontSize: 12 }}/> Vierge</TableCell>
                                        ) : record.state === State.IN_PROGRESS ? (
                                            <TableCell className="!text-center !text-orange-400"><CircleIcon sx={{ fontSize: 12 }}/> En cours</TableCell>
                                        ) : record.state === State.SEND_ENGINEER ? (
                                            <TableCell className="!text-center !text-blue-600"><CircleIcon sx={{ fontSize: 12 }}/> Envoyé à l'ingénieur</TableCell>
                                        ) : (
                                            <TableCell className="!text-center !text-green-600"><CircleIcon sx={{ fontSize: 12 }}/> Complété</TableCell>
                                        )}
                                        <TableCell className="!text-center"><Button variant='contained' size='small' className={record.state === State.EMPTY || record.path === null ? '!bg-grey' : '!bg-black'} onClick={() => handleDownloadRecord(record)} disabled={record.state === State.EMPTY || record.path === null ? true : false}>Télécharger</Button></TableCell>
                                        <TableCell className="!text-center"><Button variant='contained' size='small' className={record.state === State.SEND_ENGINEER ? '!bg-grey' : '!bg-black'} onClick={() => navigate(`form/${record.id}`, {state: {record: record}})} disabled={record.state === State.SEND_ENGINEER ? true : false}>Formulaire</Button></TableCell>
                                        <TableCell className="!text-center">
                                            <IconButton onClick={() => handleOpenDialogDelete(record.id)}>
                                                <DeleteIcon sx={{ color: red[500]}} className="text-black" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box className="flex flex-col p-3 bg-white">
                <Box>
                    <Typography variant="h5" className="!font-bold">Créer une nouvelle fiche</Typography>
                </Box>
                <Box className="flex flex-col space-y-2">
                    <Box>
                        <Typography variant="subtitle1">Choisissez un document à publiposter pour cette phase :</Typography>
                    </Box>
                    <Box className="flex space-x-10">
                        <FormControl className="flex !flex-row space-x-5 w-5/6 items-center">
                            <Box className="w-full">
                                <InputLabel id="select-doc-type-label">Document Type</InputLabel>
                                <Select
                                labelId="select-doc-type-label"
                                id="select-doc-type"
                                value={newRecord.recordType}
                                label="Document Type"
                                onChange={handleChangeSelect}
                                className="w-full"
                                >
                                    {recordTypeValues.map((value: string, index: number) => (
                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <TextField 
                                id="name"
                                name="name"
                                label="Nom de la fiche"
                                type="text"
                                variant="outlined"
                                size='small'
                                value={newRecord.name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRecordName(event)}
                                className="w-full"
                            />
                        </FormControl>
                        <Button variant="contained" className={newRecord.name.length === 0 ? '!bg-grey' : '!bg-black'} onClick={publishRecord} disabled={newRecord.name.length === 0 ? true : false}>
                            Publiposter
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box className="flex flex-col p-3 space-y-6 bg-white">
                <Box className="flex items-center justify-center">
                    <Button variant="contained" size='medium'className="!bg-black" onClick={() => setOpenDialogDeleteBuilding(true)}>
                        Supprimer l'immeuble
                    </Button>
                </Box>
            </Box>

            <Dialog open={openDialogUpdateBuildingName} onClose={() => setOpenDialogUpdateBuildingName(false)}>
                <DialogTitle>Modifier le nom du dossier</DialogTitle>
                <DialogContent>
                    <DialogContentText className='text-center !font-bold'>Saisir le nouveau nom</DialogContentText>
                    <TextField 
                        id="name"
                        name="name"
                        type="text"
                        variant="outlined"
                        size='small'
                        value={updateBuilding?.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeUpdateBuilding(event)}
                        className="w-full"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogUpdateBuildingName(false)}>Annuler</Button>
                    <Button disabled={updateBuilding.name.length === 0} onClick={() => saveUpdateBuilding()}>Envoyer</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogDelete} onClose={() => setOpenDialogDelete(false)}>
                <DialogTitle>
                   Supprimer la fiche
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous supprimer cette fiche ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogDelete(false)}>Annuler</Button>
                    <Button onClick={() => deleteRecord(recordIdToUpdateOrDelete)}>Supprimer</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogSendEngineer} onClose={() => setOpenDialogSendEngineer(false)}>
                <DialogTitle>
                    Envoyer les fiches à l'ingénieur
                </DialogTitle>
                <DialogContent>
                    {recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.SEND_ENGINEER).map((record) => (
                        <Box key={record.id} className='flex justify-center space-x-2'>
                            <Switch 
                                onChange={() => handleChangeSwitchSendToEngineer(record)}
                                checked={recordsListToSendToEngineer.some((selectedRecord) => selectedRecord.id === record.id)}
                            />
                            <Typography className='flex items-center'>{record.name}</Typography>
                            <Typography className='flex items-center'>{record.recordType}</Typography>
                            {record.state === State.IN_PROGRESS ? (
                                    <Typography className="flex items-center !text-orange-400"><CircleIcon sx={{ fontSize: 12 }}/> &nbsp; En cours</Typography>
                                ) : (
                                    <Typography className="flex items-center !text-blue-600"><CircleIcon sx={{ fontSize: 12 }}/> &nbsp; Envoyé à l'ingénieur</Typography>
                                )
                            }
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button onClick={selectAllToSendToEngineer}>
                            {recordsListForMultipleDownload.length === recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.COMPLETED).length ? 'Tout désélectionner' : 'Tout sélectionner'}
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={() => setOpenDialogSendEngineer(false)}>Annuler</Button>
                        <Button disabled={recordsListToSendToEngineer.length === 0} onClick={() => sendMail()}>Envoyer</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogUpdateRecordName} onClose={() => setOpenDialogUpdateRecordName(false)}>
                <DialogTitle>Modifier le nom de la fiche</DialogTitle>
                <DialogContent>
                    <DialogContentText className='text-center !font-bold'>Saisir le nouveau nom</DialogContentText>
                    <TextField 
                        id="name"
                        name="name"
                        type="text"
                        variant="outlined"
                        size='small'
                        value={updateRecord.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeUpdateRecord(event)}
                        className="w-full"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogUpdateRecordName(false)}>Annuler</Button>
                    <Button disabled={updateRecord.name.length === 0} onClick={() => saveUpdateRecord(recordIdToUpdateOrDelete)}>Envoyer</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogMultipleDownload} onClose={() => setOpenDialogMultipleDownload(false)}>
                <DialogTitle>
                    Sélectionner les fiches que vous voulez télécharger
                </DialogTitle>
                <DialogContent>
                    {recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.COMPLETED).map((record) => (
                        <Box key={record.id} className='flex justify-center space-x-2'>
                            <Switch 
                                onChange={() => handleChangeSwitchMultipleDownload(record)}
                                checked={recordsListForMultipleDownload.some((selectedRecord) => selectedRecord.id === record.id)}
                            />
                            <Typography className='flex items-center'>{record.name}</Typography>
                            <Typography className='flex items-center'>{record.recordType}</Typography>
                            {record.state === State.IN_PROGRESS ? (
                                    <Typography className="flex items-center !text-orange-400"><CircleIcon sx={{ fontSize: 12 }}/> &nbsp; En cours</Typography>
                                ) : (
                                    <Typography className="flex items-center !text-green-600"><CircleIcon sx={{ fontSize: 12 }}/> &nbsp; Complété</Typography>
                                )
                            }
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button onClick={selectAllForDowload}>
                            {recordsListForMultipleDownload.length === recordsList.filter((record) => record.state === State.IN_PROGRESS || record.state === State.COMPLETED).length ? 'Tout désélectionner' : 'Tout sélectionner'}
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={() => setOpenDialogMultipleDownload(false)}>Annuler</Button>
                        <Button disabled={recordsListForMultipleDownload.length === 0} onClick={() => multipleDownload()}>Envoyer</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogDeleteBuilding} onClose={() => setOpenDialogDeleteBuilding(false)}>
                <DialogTitle>
                    Etes-vous sûr de vouloir supprimer cet immeuble ?
                </DialogTitle>
                <DialogContent>
                    <Typography><strong>Adresse de l'immeuble :</strong> {building?.address.number} {building?.address.street} {building?.address.zipCode} {building?.address.city} {building?.address.country}</Typography>
                    <Typography><strong>Nom du bureau d'étude :</strong> {building?.designOffice.businessName}</Typography>
                    <Typography><strong>Email du bureau d'étude :</strong> {building?.designOffice.email}</Typography>
                    <Typography><strong>Numéro de téléphone du bureau d'étude :</strong> {building?.designOffice.phoneNumber}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button onClick={() => setOpenDialogDeleteBuilding(false)}>Annuler</Button>
                        <Button onClick={deleteBuilding}>Supprimer</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Backdrop
                className='space-x-6'
                sx={{ color: '#fff' }}
                open={isLoadingTitlePage || isLoadingMergeDocx}
            >
                <CircularProgress color="inherit" />
                <Typography>Un fichier est en cours de génération, merci d'attendre la fin de l'opération</Typography>
            </Backdrop>
        </Box>
    )
}

export default BuildingPage;