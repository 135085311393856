export enum RecordType {
    ASCENSEURS = 'Ascenseurs',
    CAVES = 'Caves',
    COLLECTIVE = 'Collective',
    EAU_FROIDE = 'Eau froide',
    ELECTRICITE = 'Electricite',
    ESPACE_VERTS_COUR = 'Espaces verts Cour',
    ETAT_EXISTANT = 'Etat existant',
    EVACUATIONS_EU_EV = 'Evacuations EU / EV',
    FACADE = 'Facade',
    FENETRE_OCCULTATIONS = 'Fenetres et occultations',
    FENETRE_OCCULTATIONS2 = 'Fenetres et occultations2',
    GAZ = 'Gaz',
    HORIZONTALES = 'Horizontales',
    INDIVIDUELLE = 'Individuelle',
    LOCAL_AUTRES = 'Local autres',
    LOCAL_OM = 'Local OM',
    LOCAUX_NON_CHAUFFES = 'Locaux non chauffes',
    SECURISATION = 'Securisation',
    SECURITE_INCENDIE = 'Securite incendie',
    STATIONNEMENT = 'Stationnement',
    STRUCTURE = 'Structure',
    TOITURE = 'Toiture',
    USAGES_RECOMMANDATION = 'Usages et recommandation',
    VENTILATION = 'Ventilation',
    VERTICALES = 'Verticales',
    VO = 'VO',
}
  