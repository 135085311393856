import { Box, Button, LinearProgress, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../axios/axios";
import { CreateValueRecordDto } from "../dtos/create-value-record.dto";
import { RecordTemplateDto } from "../dtos/record-template.dto";
import { UpdateStateRecordDto } from "../dtos/update-record.dto";
import { State } from "../enum/state.enum";
import useAuth from "../hooks/useAuth";
import { LabelRecord } from "../models/label-record.model";
import { Record } from '../models/record.model';
import { StandardRecord } from "../models/standard-record.model";
import Swal from "sweetalert2";
import { RecordType } from "../enum/record-type.enum";
import { FileAndLabelDto } from "../dtos/fileAndLabel.dto";

const Form = () => {
    let { buildingId, recordId } = useParams();
    const { state } = useLocation();
    const { person } = useAuth();
    const navigate = useNavigate();
    const [headers, setHeaders] = useState({'Authorization': 'Bearer ' + person?.accessToken})
    
    const [labelRecordList, setLabelRecordList] = useState<LabelRecord[]>([]);
    const [record, setRecord] = useState<Record>(state !== null ? state.record : null);
    const [valueRecordList, setValueRecordList] = useState<CreateValueRecordDto[]>([]);

    const [imagePathInValueRecord, setImagePathValueRecord] = useState<CreateValueRecordDto[]>([]);

    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);


    const generateForm = () => {       
        if (record !== null) {
            console.log(headers)
            axios.get<StandardRecord>(`/standard-record/type?recordType=${record?.recordType}`, {headers})
                .then(async (response) => {
                    console.log(response.data)
                    setLabelRecordList(response.data.labelsRecord);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const cancelForm = () => {
        setValueRecordList([]);
        navigate(`/building/${buildingId}`)
    }


    const handleChangeInput = (labelRecord: LabelRecord, event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(valueRecordList)
        const newValueRecord: CreateValueRecordDto[] = [...valueRecordList];
        if (newValueRecord.filter((createValue) => createValue.labelRecord.id === labelRecord.id).length !== 0) {
            newValueRecord.filter((createValue) => createValue.labelRecord.id === labelRecord.id)[0].value = event.target.value;
        } else {
            newValueRecord.push({value: event.target.value, record: record, labelRecord: labelRecord, isForEngineer: false})
        }
        setValueRecordList(newValueRecord);
        console.log(newValueRecord)
    };

    
    const handleChangeSwitch = (labelRecord: LabelRecord, event: React.ChangeEvent<HTMLInputElement>) => {
        const newValueRecord: CreateValueRecordDto[] = [...valueRecordList];
        if (newValueRecord.filter((createValue) => createValue.labelRecord.id === labelRecord.id).length !== 0) {
            newValueRecord.filter((createValue) => createValue.labelRecord.id === labelRecord.id)[0].isForEngineer = event.target.checked;
            newValueRecord.filter((createValue) => createValue.labelRecord.id === labelRecord.id)[0].value = '';
        } else {
            newValueRecord.push({value: '', record: record, labelRecord: labelRecord, isForEngineer: event.target.checked})
        }
        setValueRecordList(newValueRecord);
        console.log(newValueRecord)
    }


    const generateDocx = async (recordName: string) => {
        const endedValue: RecordTemplateDto = {
            nom_fiche: recordName,
            descriptif_general: valueRecordList.filter((value) => value.labelRecord.id === 1).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 1)[0].value : "",
            etat_general: valueRecordList.filter((value) => value.labelRecord.id === 2).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 2)[0].value : "",
            gestes_usage: valueRecordList.filter((value) => value.labelRecord.id === 3).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 3)[0].value : "",
            contrat_entretien: valueRecordList.filter((value) => value.labelRecord.id === 4).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 4)[0].value : "",
            recommandations_travaux: valueRecordList.filter((value) => value.labelRecord.id === 5).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 5)[0].value : "",
            carac_tech: valueRecordList.filter((value) => value.labelRecord.id === 6).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 6)[0].value : "",
            accessibilite_pmr_ve: valueRecordList.filter((value) => value.labelRecord.id === 7).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 7)[0].value : "",
            cabines: valueRecordList.filter((value) => value.labelRecord.id === 8).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 8)[0].value : "",
            date_controle: valueRecordList.filter((value) => value.labelRecord.id === 9).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 9)[0].value : "",
            installation_elec: valueRecordList.filter((value) => value.labelRecord.id === 10).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 10)[0].value : "",
            analyse_qualite_archi: valueRecordList.filter((value) => value.labelRecord.id === 11).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 11)[0].value : "",
            carac_thermique_local: valueRecordList.filter((value) => value.labelRecord.id === 12).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 12)[0].value : "",
            acces_porte_cle_escalier: valueRecordList.filter((value) => value.labelRecord.id === 13).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 13)[0].value : "",
            cloisonnement: valueRecordList.filter((value) => value.labelRecord.id === 14).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 14)[0].value : "",
            carac_ventilation: valueRecordList.filter((value) => value.labelRecord.id === 15).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 15)[0].value : "",
            fiche_secu_incendie: valueRecordList.filter((value) => value.labelRecord.id === 16).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 16)[0].value : "",
            installation_elec_eclairage: valueRecordList.filter((value) => value.labelRecord.id === 17).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 17)[0].value : "",
            contrainte_mitoyennete: valueRecordList.filter((value) => value.labelRecord.id === 18).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 18)[0].value : "",
            carac_materiaux_existants: valueRecordList.filter((value) => value.labelRecord.id === 19).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 19)[0].value : "",
            description_ouvrage_etancheites: valueRecordList.filter((value) => value.labelRecord.id === 20).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 20)[0].value : "",
            balcon: valueRecordList.filter((value) => value.labelRecord.id === 21).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 21)[0].value : "",
            carac_thermique: valueRecordList.filter((value) => value.labelRecord.id === 22).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 22)[0].value : "",
            descriptif_general_installations_collectives_organisation: valueRecordList.filter((value) => value.labelRecord.id === 23).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 23)[0].value : "",
            local_chaufferie: valueRecordList.filter((value) => value.labelRecord.id === 24).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 24)[0].value : "",
            comptage_en_chaufferie: valueRecordList.filter((value) => value.labelRecord.id === 25).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 25)[0].value : "",
            type_energie: valueRecordList.filter((value) => value.labelRecord.id === 26).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 26)[0].value : "",
            carac_systeme_production_energie: valueRecordList.filter((value) => value.labelRecord.id === 27).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 27)[0].value : "",
            type_production_ecs_comptage_efr: valueRecordList.filter((value) => value.labelRecord.id === 28).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 28)[0].value : "",
            regulation: valueRecordList.filter((value) => value.labelRecord.id === 29).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 29)[0].value : "",
            carac_emetteurs: valueRecordList.filter((value) => value.labelRecord.id === 30).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 30)[0].value : "",
            regulation_emetteurs: valueRecordList.filter((value) => value.labelRecord.id === 31).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 31)[0].value : "",
            organe_distribution: valueRecordList.filter((value) => value.labelRecord.id === 32).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 32)[0].value : "",
            materiaux_configuration: valueRecordList.filter((value) => value.labelRecord.id === 33).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 33)[0].value : "",
            abonnement_compteur_general: valueRecordList.filter((value) => value.labelRecord.id === 34).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 34)[0].value : "",
            accessibilites_au_plomb: valueRecordList.filter((value) => value.labelRecord.id === 35).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 35)[0].value : "",
            cas_specifique_plusieurs_colonnes_etage: valueRecordList.filter((value) => value.labelRecord.id === 36).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 36)[0].value : "",
            traversee_plancher_gaine_techniques: valueRecordList.filter((value) => value.labelRecord.id === 37).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 37)[0].value : "",
            contrat_fourniture_electricite: valueRecordList.filter((value) => value.labelRecord.id === 38).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 38)[0].value : "",
            carac_techniques_alimentation_generale: valueRecordList.filter((value) => value.labelRecord.id === 39).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 39)[0].value : "",
            carac_installations_parties_communes: valueRecordList.filter((value) => value.labelRecord.id === 40).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 40)[0].value : "",
            carac_eclairages_parties_communes: valueRecordList.filter((value) => value.labelRecord.id === 41).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 41)[0].value : "",
            carac_gaines_techniques: valueRecordList.filter((value) => value.labelRecord.id === 42).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 42)[0].value : "",
            localisation_compteurs_electriques_individuels: valueRecordList.filter((value) => value.labelRecord.id === 43).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 43)[0].value : "",
            bornes_recharge_vehicule_electrique: valueRecordList.filter((value) => value.labelRecord.id === 44).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 44)[0].value : "",
            signalement_arbres_haute_tige: valueRecordList.filter((value) => value.labelRecord.id === 45).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 45)[0].value : "",
            carac_cour_et_espaces_verts: valueRecordList.filter((value) => value.labelRecord.id === 46).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 46)[0].value : "",
            cheminement_pietons_pmr_ve: valueRecordList.filter((value) => value.labelRecord.id === 47).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 47)[0].value : "",
            eclairages_exterieurs: valueRecordList.filter((value) => value.labelRecord.id === 48).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 48)[0].value : "",
            eaux_pluviales_caniveaux: valueRecordList.filter((value) => value.labelRecord.id === 49).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 49)[0].value : "",
            stationnements_exterieurs: valueRecordList.filter((value) => value.labelRecord.id === 50).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 50)[0].value : "",
            retour_enquete_logement_adaptee_thematique: valueRecordList.filter((value) => value.labelRecord.id === 52).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 52)[0].value : "",
            accessibilite_immeuble_a_tous: valueRecordList.filter((value) => value.labelRecord.id === 53).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 53)[0].value : "",
            vegetalisation_et_biodiversite: valueRecordList.filter((value) => value.labelRecord.id === 54).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 54)[0].value : "",
            ecomobilite_mobilite_durable: valueRecordList.filter((value) => value.labelRecord.id === 55).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 55)[0].value : "",
            gestion_de_l_eau: valueRecordList.filter((value) => value.labelRecord.id === 56).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 56)[0].value : "",
            gestion_des_dechets: valueRecordList.filter((value) => value.labelRecord.id === 57).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 57)[0].value : "",
            materiaux: valueRecordList.filter((value) => value.labelRecord.id === 58).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 58)[0].value : "",
            plrs_batiments: valueRecordList.filter((value) => value.labelRecord.id === 59).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 59)[0].value : "",
            situation_colonnes_ev_appartements: valueRecordList.filter((value) => value.labelRecord.id === 60).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 60)[0].value : "",
            chutes_facades_tout_en_un: valueRecordList.filter((value) => value.labelRecord.id === 61).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 61)[0].value : "",
            pompes_relevage: valueRecordList.filter((value) => value.labelRecord.id === 62).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 62)[0].value : "",
            gaine_technique: valueRecordList.filter((value) => value.labelRecord.id === 63).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 63)[0].value : "",
            descriptif_general_menuiseries_fenetres_volets: valueRecordList.filter((value) => value.labelRecord.id === 64).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 64)[0].value : "",
            carac_portes_palieres_pp: valueRecordList.filter((value) => value.labelRecord.id === 66).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 66)[0].value : "",
            confort_ete_existant: valueRecordList.filter((value) => value.labelRecord.id === 67).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 67)[0].value : "",
            contrat_fourniture_gaz: valueRecordList.filter((value) => value.labelRecord.id === 68).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 68)[0].value : "",
            carac_techniques_halls_couloirs_distribution: valueRecordList.filter((value) => value.labelRecord.id === 69).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 69)[0].value : "",
            securisation_portes_parois_cf: valueRecordList.filter((value) => value.labelRecord.id === 70).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 70)[0].value : "",
            boites_aux_lettres: valueRecordList.filter((value) => value.labelRecord.id === 71).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 71)[0].value : "",
            descriptif_general_installations_privatives: valueRecordList.filter((value) => value.labelRecord.id === 72).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 72)[0].value : "",
            type_energie_proportion: valueRecordList.filter((value) => value.labelRecord.id === 73).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 73)[0].value : "",
            carac_systemes_production_energie_proportion: valueRecordList.filter((value) => value.labelRecord.id === 74).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 74)[0].value : "",
            type_production_ecs: valueRecordList.filter((value) => value.labelRecord.id === 75).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 75)[0].value : "",
            evacuation_produits_combustion: valueRecordList.filter((value) => value.labelRecord.id === 76).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 76)[0].value : "",
            type_emetteurs: valueRecordList.filter((value) => value.labelRecord.id === 77).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 77)[0].value : "",
            carac_local: valueRecordList.filter((value) => value.labelRecord.id === 78).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 78)[0].value : "",
            mobiliers_dispositifs_stockage: valueRecordList.filter((value) => value.labelRecord.id === 79).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 79)[0].value : "",
            proprete_nuisibles: valueRecordList.filter((value) => value.labelRecord.id === 80).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 80)[0].value : "",
            securisation: valueRecordList.filter((value) => value.labelRecord.id === 81).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 81)[0].value : "",
            conteneurs: valueRecordList.filter((value) => value.labelRecord.id === 82).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 82)[0].value : "",
            presence_materiaux_a_risques: valueRecordList.filter((value) => value.labelRecord.id === 83).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 83)[0].value : "",
            interphone_digicode: valueRecordList.filter((value) => value.labelRecord.id === 84).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 84)[0].value : "",
            carac_clotures_portails: valueRecordList.filter((value) => value.labelRecord.id === 85).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 85)[0].value : "",
            portes_de_garages: valueRecordList.filter((value) => value.labelRecord.id === 86).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 86)[0].value : "",
            portails_vehicules: valueRecordList.filter((value) => value.labelRecord.id === 87).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 87)[0].value : "",
            famille_type: valueRecordList.filter((value) => value.labelRecord.id === 88).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 88)[0].value : "",
            carac_cf_pf_locaux: valueRecordList.filter((value) => value.labelRecord.id === 89).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 89)[0].value : "",
            eclairages_de_secours: valueRecordList.filter((value) => value.labelRecord.id === 90).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 90)[0].value : "",
            colonne_seche: valueRecordList.filter((value) => value.labelRecord.id === 91).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 91)[0].value : "",
            acces_pompiers: valueRecordList.filter((value) => value.labelRecord.id === 92).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 92)[0].value : "",
            extincteurs: valueRecordList.filter((value) => value.labelRecord.id === 93).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 93)[0].value : "",
            reglementation_specifique_imh_igh: valueRecordList.filter((value) => value.labelRecord.id === 94).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 94)[0].value : "",
            rampes_acces_vehicules_sorties_pietons: valueRecordList.filter((value) => value.labelRecord.id === 95).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 95)[0].value : "",
            cloisonnement_boxes_individuels: valueRecordList.filter((value) => value.labelRecord.id === 96).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 96)[0].value : "",
            carac_constructives_materiaux: valueRecordList.filter((value) => value.labelRecord.id === 97).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 97)[0].value : "",
            carac_escaliers: valueRecordList.filter((value) => value.labelRecord.id === 98).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 98)[0].value : "",
            potentiel_surelevation: valueRecordList.filter((value) => value.labelRecord.id === 99).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 99)[0].value : "",
            charpente_description_caracteristiques: valueRecordList.filter((value) => value.labelRecord.id === 100).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 100)[0].value : "",
            recommandations_travaux_accessibilite_immeuble_pour_tous: valueRecordList.filter((value) => value.labelRecord.id === 101).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 101)[0].value : "",
            recommandations_travaux_vegetalisation_biodiversite: valueRecordList.filter((value) => value.labelRecord.id === 102).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 102)[0].value : "",
            recommandations_travaux_ecomobilite: valueRecordList.filter((value) => value.labelRecord.id === 103).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 103)[0].value : "",
            recommandations_gestion_de_l_eau: valueRecordList.filter((value) => value.labelRecord.id === 104).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 104)[0].value : "",
            recommandations_gestion_des_dechets: valueRecordList.filter((value) => value.labelRecord.id === 105).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 105)[0].value : "",
            materiaux_caracteristiques: valueRecordList.filter((value) => value.labelRecord.id === 106).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 106)[0].value : "",
            presence_chaudiere_gaz_tirage_naturel_vmc_gaz: valueRecordList.filter((value) => value.labelRecord.id === 107).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 107)[0].value : "",
            presence_entree_air_pp_pc: valueRecordList.filter((value) => value.labelRecord.id === 108).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 108)[0].value : "",
            presence_conduits_fumees: valueRecordList.filter((value) => value.labelRecord.id === 109).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 109)[0].value : "",
            descriptif_general_escaliers: valueRecordList.filter((value) => value.labelRecord.id === 110).length !== 0 ? valueRecordList.filter((value) => value.labelRecord.id === 110)[0].value : "",
        }

        await axios.post(`/generate-docx/${record?.id}`, endedValue, {headers})
            .then((response) => {
                console.log(response)
            })
            .catch((error) => console.log(error))
    }

    const updateStateRecord = (updateStateRecordDto: UpdateStateRecordDto) => {
        axios.put(`/record/edit-state/${record?.id}`, updateStateRecordDto, {headers})
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
    }

    const saveValues = () => {
        console.log(valueRecordList)
        axios.post('/value-record', valueRecordList, {headers})
            .then((response) => {
                console.log(response)
                navigate(`/building/${buildingId}`)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const saveForm = async (recordName: string) => {
        await generateDocx(recordName);

        if (record.state === State.EMPTY) {
            const updateStateRecordDto: UpdateStateRecordDto = {
                state: State.IN_PROGRESS    
            }

            updateStateRecord(updateStateRecordDto);
        } else if (record.state === State.SEND_ENGINEER) {
            const updateStateRecordDto: UpdateStateRecordDto = {
                state: State.COMPLETED    
            }

            updateStateRecord(updateStateRecordDto);
        }

        saveValues();
    }

    function capitalizeFirstLetter(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    const isAuthorizeToAccessToPage = () => {
        const queryString = window.location.search;
        const accessToken = new URLSearchParams(queryString).get('accessToken');
        if (record !== null) {
            if (record.state === State.EMPTY || record.state === State.IN_PROGRESS || record.state === State.COMPLETED) {
                console.log('admin')
            } else if (record.state === State.SEND_ENGINEER && accessToken !== null) {
                console.log('engineer')
                axios.post<boolean>('/auth/token-validate', {accessToken: accessToken}, {headers})
                    .then((response) => {
                        setHeaders({'Authorization': 'Bearer ' + accessToken});
                        console.log('Bearer ' + accessToken)
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        alert("Token invalid")
                    })
            } else {
                navigate(`/building/${buildingId}`)
            }
        }
    }
    
    useEffect(() => {
        axios.get(`/record/${recordId}`)
            .then((response) => {
                console.log(response)
                setRecord(response.data)
            })
            .catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        isAuthorizeToAccessToPage();
        generateForm();
        if (record !== null) {
            axios.get<CreateValueRecordDto[]>(`/value-record/only-value/${record.id}`, {headers})
            .then((response) => {
                console.log(response)
                if (record.state === State.EMPTY || record.state === State.IN_PROGRESS || record.state === State.COMPLETED) {
                    setValueRecordList(response.data);
                } else if (record.state === State.SEND_ENGINEER) {
                    setValueRecordList(response.data);
                }
            })
            .catch((error) => console.log(error))

            axios.get<CreateValueRecordDto[]>(`/value-record/only-image/${record.id}`, {headers})
            .then((response) => {
                console.log(response)
                setImagePathValueRecord(response.data)
            })
            .catch((error) => console.log(error))
        }
    }, [record])


    const [selectedFile, setSelectedFile] = useState<FileAndLabelDto | null>(null);
    const [namePreviousImage, setNamePreviousImage] = useState<string>();
    
    const handleFileChange = (labelRecord: LabelRecord, event: React.ChangeEvent<HTMLInputElement>) => {
        // Récupérer le fichier sélectionné par l'utilisateur
        const file = event.target.files && event.target.files[0];
      
        // Mettre à jour l'état avec le fichier sélectionné
        setSelectedFile({
            file: file || null,
            label: labelRecord
        });

        if (imagePathInValueRecord.length !== 0) {
            setNamePreviousImage(imagePathInValueRecord[0].value)
        }
    };


    const handleUpload = (labelRecord: LabelRecord) => {
        console.log(selectedFile)

        if (selectedFile && selectedFile.file) {
            const formData = new FormData();
            const file = selectedFile.file;
            formData.append("file", file);

            setIsLoadingImage(true)

            axios.post('/file-upload/image', formData, {headers})
                .then((response) => {
                    console.log(response)
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Succès",
                        text: 'L\'image a bien été téléversée',
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    });

                    const newValueRecordImage: CreateValueRecordDto[] = [...imagePathInValueRecord];
                    if (newValueRecordImage.filter((createValueImage) => createValueImage.labelRecord.id === labelRecord.id).length !== 0) {
                        const pathPreviousThenNewImage = newValueRecordImage.filter((createValueImage) => createValueImage.labelRecord.id === labelRecord.id)[0]
                        axios.delete(`/file-upload?fileName=${pathPreviousThenNewImage.value}`,{headers})
                            .then((response) => console.log(response))
                            .catch((error) => console.log(error))
                        pathPreviousThenNewImage.value = response.data;
                    } else {
                        newValueRecordImage.push({value: response.data, record: record, labelRecord: labelRecord, isForEngineer: false})
                    }
                    setImagePathValueRecord(newValueRecordImage);

                    console.log(newValueRecordImage)

                    axios.post('/value-record', newValueRecordImage, {headers})
                        .then((response) => {
                            console.log(response.data)
                            setImagePathValueRecord(response.data)
                        })
                        .catch((error) => console.log(error))

                    setSelectedFile(null);
                    
                })
                .catch((error) => console.log(error))
                .finally(() => setIsLoadingImage(false))
        }
    };

    return (
        <Box className="flex flex-col w-full mt-3 space-y-4">
            <Box className="flex flex-col px-10 py-8 bg-white">
                <Box className="flex w-full mb-4 space-x-4">
                    <Typography variant="h4" className="w-full !font-bold">Formulaire</Typography>
                    {record && (record.state === State.EMPTY || record.state === State.IN_PROGRESS) ? (
                        <Typography variant="subtitle2" className="text-justify" color="grey">Activer les boutons Switch pour les réponses à remplir par l'ingénieur.</Typography>
                    ) :  record && record.state === State.SEND_ENGINEER ? (
                        <Typography variant="subtitle2" className="text-justify" color="grey">Les champs activés par défaut ont été sélectionnés par l'ingénieur. Vous pouvez modifier vous-même cette sélection en cliquant sur les boutons Switch.</Typography>
                    ) : <Typography></Typography>
                    }
                </Box>
                <Box>
                    <Box>
                        <Box className="mb-2">
                            <Typography variant="h6" className="!font-bold">Nom de la fiche : {record?.name}</Typography>
                            <Typography variant="h6" className="!font-bold">Type de la fiche : {record?.recordType}</Typography>
                        </Box>
                        <Box className="mb-6 space-y-6">
                            <Box className="flex flex-col space-y-2">
                                {labelRecordList.filter((label) => ![111,112,113,114,115,116].includes(label.id)).map((label) => (
                                    <Box key={label.id}>
                                        <Typography variant="subtitle1" className="!font-bold">{capitalizeFirstLetter(label.name)}</Typography>
                                        <Box className="flex items-center">
                                            <Switch 
                                                checked={valueRecordList.filter((valueRecord) => valueRecord.labelRecord.id === label.id)[0]?.isForEngineer ? true : false}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSwitch(label, event)} 
                                            />
                                            <TextField
                                                id={label.id.toString()}
                                                name={label.name}
                                                placeholder="Réponse"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                className="w-full"
                                                multiline
                                                disabled={
                                                    ((valueRecordList.filter((valueRecord) => valueRecord.labelRecord.id === label.id)[0]?.isForEngineer === true) && (record.state === State.EMPTY || record.state === State.IN_PROGRESS || record.state === State.COMPLETED)) 
                                                    || (((valueRecordList.filter((valueRecord) => valueRecord.labelRecord.id === label.id)[0]?.isForEngineer === false) || (valueRecordList.filter((valueRecord) => valueRecord.labelRecord.id === label.id).length === 0)) && (record.state === State.SEND_ENGINEER))
                                                    ? true : false}
                                                value={valueRecordList.filter((valueRecord) => valueRecord.labelRecord.id === label.id)[0]?.value ?? ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(label, event)}
                                            />
                                        </Box>
                                    </Box>
                                ))}
                                {record?.state === State.EMPTY || record?.state === State.IN_PROGRESS ? record.recordType !== RecordType.ETAT_EXISTANT && record.recordType !== RecordType.USAGES_RECOMMANDATION ? labelRecordList.filter((label) => label.id === 111).map((label) => (
                                    <Box key={label.id} className="flex flex-col space-y-3">
                                        <input
                                            type="file"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(label, event)}
                                            accept="image/*"
                                        />
                                        <Button className="w-1/6" variant="contained" color="primary" onClick={() => handleUpload(label)}>
                                            Téléverser
                                        </Button>
                                        {isLoadingImage ? (
                                            <LinearProgress/>
                                        ) : null}
                                        {imagePathInValueRecord.length !== 0 ? (
                                            <Typography variant="subtitle2" className="text-justify" color="grey">Il existe déjà une image pour cette fiche, téléverser en une nouvelle pour la remplacer</Typography>
                                        ) : (<Typography></Typography>)}
                                    </Box>
                                )) : labelRecordList.filter((label) => label.id >= 112 && label.id <= 116).map((label) => (
                                    <Box key={label.id} className="flex space-x-3">
                                        <Typography variant="subtitle1" className="flex items-center !font-bold">{capitalizeFirstLetter(label.name)}</Typography>
                                        <input
                                            type="file"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(label, event)}
                                            accept="image/*"
                                        />
                                        <Button className="w-1/6" variant="contained" color="primary" onClick={() => handleUpload(label)} disabled={selectedFile?.label.id !== label.id}>
                                            Téléverser
                                        </Button>
                                        {imagePathInValueRecord.find((imagePath) => imagePath.labelRecord.id === label.id) !== undefined ? (
                                            <Typography variant="subtitle2" className="text-justify" color="grey">Il existe déjà une image pour cette fiche, téléverser en une nouvelle pour la remplacer</Typography>
                                        ) : (<Typography></Typography>)}
                                    </Box>
                                )) : (<Box></Box>)}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="flex justify-end space-x-6">
                    <Button variant='contained'className='!bg-black' onClick={cancelForm}>Annuler</Button>
                    <Button variant='contained'className={isLoadingImage ? '!bg-grey' : '!bg-black'} disabled={isLoadingImage} onClick={() => {saveForm(record?.name)}}>{record?.state === State.EMPTY || record?.state === State.IN_PROGRESS ? 'Enregistrer' : 'Terminer le remplissage'}</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Form;