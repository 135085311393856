import { Box, Button } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { Role } from "../enum/role.enum";

const Nav = () => {
  const { person, logout } = useAuth();

  return (
    <Box className="flex items-center space-x-5">
      {person && person.role === Role.ADMIN ? (
          <Button variant="contained" className="!bg-black">
            Gestion
          </Button>
      ) : null}
      <Button variant="contained" className="!bg-black" onClick={logout}>
        Se déconnecter
      </Button>
    </Box>
  );
};

export default Nav;
