import {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import Swal from "sweetalert2";

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.log(error);

    const messagePopUp = error.response?.status === 401 ? error.config?.headers.Authorization?.toString().includes('Bearer') ? 'Veuillez vous reconnecter' : 'Email ou mot de passe incorrect' : error.response?.status + ' ' + error.response?.statusText;

    Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Erreur",
        text: messagePopUp,
        showConfirmButton: false,
        timer: 1500,
        toast: true
    });

    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}