import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, LinearProgress, Pagination, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axios/axios';
import AddressComponent from '../components/AddressComponent';
import { CreateAddressDto } from '../dtos/create-address.dto';
import { CreateBuildingDto } from '../dtos/create-building.dto';
import { CreateDesignOfficeDto } from '../dtos/create-design-office.dto';
import useAuth from '../hooks/useAuth';
import { Building } from '../models/building.model';
import DesignOfficeComponent from '../components/DesignOfficeComponent';
import Swal from 'sweetalert2';
import { GifBox } from '@mui/icons-material';


const Home = () => {
  const { person } = useAuth();
  const headers = {'Authorization': 'Bearer ' + person?.accessToken}
  const navigate = useNavigate();

  const numberBuildingsPerPage = 10;
  const [numberPagesTotal, setNumberPagesTotal] = useState<number>();
  const [page, setPage] = useState<number>(1);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const initialCreateAddressDto: CreateAddressDto = {
    number: '',
    street: '',
    zipCode: '',
    city: '',
    country: ''
  }

  const initialCreateDesignOfficeDto: CreateDesignOfficeDto = {
    businessName: '',
    email: '',
    phoneNumber: '',
    address: initialCreateAddressDto
  }

  const initialCreateBuildingDto: CreateBuildingDto = {
    name: '',
    photoPath: '',
    address: initialCreateAddressDto,
    designOffice: initialCreateDesignOfficeDto
  }

  const [addressBuilding, setAddressBuilding] = useState<CreateAddressDto>(initialCreateAddressDto);

  const [addressDesignOffice, setAddressDesignOffice] = useState<CreateAddressDto>(initialCreateAddressDto);

  const [infoDesignOffice, setInfoDesignOffice] = useState<CreateDesignOfficeDto>(initialCreateDesignOfficeDto);

  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  const handleChangeAddressBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setAddressBuilding(({...addressBuilding, [name]: value}))
  }

  const handleChangeAddressDesignOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setAddressDesignOffice(({...addressDesignOffice, [name]: value}))
    setInfoDesignOffice({
      ...infoDesignOffice,
      address: {
        ...infoDesignOffice.address,
        [name]: value
      }
    })
  }

  const handleChangeDesignOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setInfoDesignOffice(({...infoDesignOffice, [name]: value}))
  }

  const [infoBuilding, setInfoBuilding] = useState<CreateBuildingDto>(initialCreateBuildingDto);

  const handleChangeInputName =  (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setInfoBuilding(({...infoBuilding, [name]: value}))
  }

  const handleSaveBuilding = () => {
    const updateInfoBuilding: CreateBuildingDto = {
      ...infoBuilding,
      address: addressBuilding,
      designOffice: infoDesignOffice
    }

    axios.post('/building', updateInfoBuilding, {headers})
      .then((response) => {
        console.log(response)
        resetAllInputsValue();
      })
      .catch((error) => {
        console.log(error)
      })
    setOpenDialog(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    deleteImageInMinio(infoBuilding.photoPath);
    resetAllInputsValue();
  }

  const resetAllInputsValue = () => {
    setInfoBuilding(initialCreateBuildingDto);
    setAddressBuilding(initialCreateAddressDto);
    setInfoDesignOffice(initialCreateDesignOfficeDto);
    setAddressDesignOffice(initialCreateAddressDto);
  }

  const deleteImageInMinio = (photoPath: string | undefined) => {
    if (photoPath !== undefined && photoPath !== '') {
      axios.delete(`/file-upload?fileName=${photoPath}`, {headers})
          .then((response) => console.log(response))
          .catch((error) => console.log(error))
    }
  }

  const [buildingsList, setBuildingsList] = useState<Building[]>();
  const [buildingsListCopy, setBuildingsListCopy] = useState<Building[]>();

  useEffect(() => {
    axios.get<Building[]>('/building', {headers})
      .then((response) => {
        const tempBuildingList: Building[] = response.data.filter((building) => building.deletedAt === null)
        setBuildingsList(tempBuildingList)
        setBuildingsListCopy(tempBuildingList)
        setNumberPagesTotal(Math.ceil(response.data.filter((building) => building.deletedAt === null).length / numberBuildingsPerPage))
        console.log(response.data)
        response.data.filter((building) => building.photoPath !== '').forEach((building) => {
          axios.get(`/file-upload?fileName=${building.photoPath}`, {headers})
          .then((response) => {
            console.log(response)
            const index = tempBuildingList?.findIndex((buildingElement) => buildingElement.id === building.id);

            const updateBuilding = [...tempBuildingList];

            updateBuilding[index].photoPath = response.data;
            setBuildingsList(updateBuilding);
            setBuildingsListCopy(updateBuilding);
          })
          .catch((error) => console.log(error))
        })

      })
      .catch((error) => {
        console.log(error)
      })
  }, [infoBuilding]);

  const saveBuildingIsDisabled = (addressBuilding: CreateAddressDto, designOffice: CreateDesignOfficeDto) => {
    if (infoBuilding.name.length === 0 || saveAddressIsDisabled(addressBuilding) || designOffice.businessName.length === 0 || designOffice.email.length === 0 || designOffice.phoneNumber.length === 0 || saveAddressIsDisabled(designOffice.address) || isLoadingImage) {
        return true;
    }
    return false;
  }

  const saveAddressIsDisabled = (address: CreateAddressDto) => {
      if (address.number.length === 0 || address.street.length === 0 || address.zipCode.length === 0 || address.city.length === 0 || address.country.length === 0) {
          return true;
      }
      return false;
  }

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [namePreviousImage, setNamePreviousImage] = useState<string>();
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Récupérer le fichier sélectionné par l'utilisateur
      const file = event.target.files && event.target.files[0];
    
      // Mettre à jour l'état avec le fichier sélectionné
      setSelectedFile(file || null);

      if (infoBuilding.photoPath && infoBuilding.photoPath !== '') {
          setNamePreviousImage(infoBuilding.photoPath)
      }
  };

  const handleUpload = () => {
    if (selectedFile) {
        const formData = new FormData();
        const file = selectedFile;
        formData.append("file", file);

        setIsLoadingImage(true);

        axios.post('/file-upload/image', formData, {headers})
          .then((response) => {
              console.log(response)
              setInfoBuilding({...infoBuilding, photoPath: response.data})
              deleteImageInMinio(namePreviousImage);

              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Succès",
                text: 'L\'image a bien été téléversée',
                showConfirmButton: false,
                timer: 1500,
                toast: true
              });
          })
          .catch((error) => console.log(error))
          .finally(() => setIsLoadingImage(false))
    }
  };

  const handleChangeSearchBar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const buildingListWithSearch = buildingsList?.filter((building) => {
      const buildingAddress = 
        (building.address.number + " " +
        building.address.street + " " +
        building.address.zipCode + " " +
        building.address.city + " " +
        building.address.country).toLowerCase();

      const searchTerm = event.target.value.toLowerCase();

      return (
        buildingAddress.includes(searchTerm)
      );
    })

    setBuildingsListCopy(buildingListWithSearch);
    if (buildingListWithSearch !== undefined) {
      setNumberPagesTotal(Math.ceil(buildingListWithSearch.length / numberBuildingsPerPage))
      setPage(1);
    }
  }

  return (
    <Box className="w-95% flex flex-col space-y-4 mt-5">
      <Box className="flex justify-end space-x-6">
        <Button variant="contained" className="!bg-black" onClick={() => setOpenDialog(true)}>
          Ajouter un immeuble
        </Button>
        <Dialog open={openDialog} onClose={handleCloseDialog} className='w-full'>
          <DialogTitle className='!text-2xl !font-bold'>Ajouter un immeuble</DialogTitle>
          <DialogContent className='space-y-4'>
            <Box className='space-y-2'>
              <DialogContentText className='text-center !font-bold'>Nom du dossier</DialogContentText>
               <FormControl className="flex flex-col items-center justify-center w-full space-y-2">
                <TextField 
                  id="name"
                  name="name"
                  label="Nom du dossier"
                  type="text"
                  variant="outlined"
                  size='small'
                  value={infoBuilding.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeInputName(event)}
                  className='w-1/2'
                />
              </FormControl>
            </Box>
            <Box className='flex space-x-4'>
              <Box className='w-full space-y-2'>
                <DialogContentText className='text-center !font-bold'>Adresse de l'immeuble</DialogContentText>
                <AddressComponent address={addressBuilding} handleChangeAddress={handleChangeAddressBuilding}/>
                <Box className="flex flex-col space-y-3">
                  <Typography>Choisir une photo de l'immeuble</Typography>
                  <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                  />  
                  <Button className="w-1/2" variant="contained" color="primary" onClick={() => handleUpload()}>
                      Téléverser
                  </Button>

                  {isLoadingImage ? (
                    <LinearProgress/>
                  ) : null}

                  {infoBuilding.photoPath !== '' ? (
                      <Typography variant="subtitle2" className="text-justify" color="grey">Il existe déjà une photo pour cet immeuble, téléverser en une nouvelle pour la remplacer</Typography>
                  ) : (<Typography></Typography>)}
                </Box>
              </Box>
              <Box className='w-full space-y-2'>
                <DialogContentText className='text-center !font-bold'>Bureau d'étude</DialogContentText>
                  <AddressComponent address={addressDesignOffice} handleChangeAddress={handleChangeAddressDesignOffice} />
                  <DesignOfficeComponent designOffice={infoDesignOffice} handleChangeDesignOffice={handleChangeDesignOffice} />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button 
              className="!bg-black"
              variant="contained"
              size="medium"
              onClick={handleCloseDialog}
            >
              Annuler
            </Button>
            <Button     
              className={saveBuildingIsDisabled(addressBuilding, infoDesignOffice) ? '!bg-grey' : '!bg-black'}
              variant="contained"
              size="medium" 
              onClick={handleSaveBuilding}
              disabled={saveBuildingIsDisabled(addressBuilding, infoDesignOffice)}
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
        <TextField
          label="Rechercher"
          variant="outlined"
          type="search"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSearchBar(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="text-black" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Grid container spacing={2} className="flex justify-center">
      {buildingsListCopy?.slice(numberBuildingsPerPage*(page-1), numberBuildingsPerPage * page).map((building) => (
        <Grid key={building.id} item className="max-w-20%" onClick={() => navigate(`/building/${building.id}`)}>
          <Paper className="flex flex-col items-center justify-center p-5">
            {building.photoPath && building.photoPath !== '' ? (
              <img
                srcSet={`${building.photoPath}`}
                src={`${building.photoPath}`}
                alt='Immeuble'
                width={40}
                height={40}
                loading="lazy"
              />
            ) : (
              <ApartmentIcon style={{ fontSize: 60 }} />
            )}
            <Typography>{building.name}</Typography>
            <Typography>{building.address.number} {building.address.street}</Typography>
            <Typography>{building.address.zipCode} {building.address.city}</Typography>
          </Paper>
        </Grid>
      ))}
      </Grid>
      <Pagination className="flex justify-center" count={numberPagesTotal} page={page} onChange={handleChangePage} />
    </Box>
  );
};

export default Home;
